export enum StatusEnum {
    Active = 1,
    Inactive = 0,
}

export enum StatusEnumWithIcons {
    '✅ Active' = 1,
    '🚫 Inactive' = 0,
}

export enum ApprovalStatusEnum {
    Pending = 0,
    Approved = 1,
}

export enum AccessStatusEnum {
    'Access' = 1,
    'Not Access' = 0,
}
