import axios, { AxiosRequestConfig, AxiosPromise, AxiosResponse } from 'axios';
import _StorageService from "./storage.service";
import {CONSTANT_CONFIG} from "../constants";
import { SECURITY_UI_URL } from "@/constants/config.constant";
import {StringUtils} from "@/utils";
const StorageService = new _StorageService();

type RequestMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'HEAD' | 'OPTIONS';

interface Request {
    method: RequestMethod;
    url: string;
    queryString?: string;
    params?: any;
    headers?: any;
    body?: any;
    responseType?: any;
}

class _HttpService {

    private httpClient;

    private baseURL = CONSTANT_CONFIG.API_GATEWAY_URL;

    private timeout = 180000; // 20000

    private headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': '',
        'X-App-Identifier': 'SecurityUI',
        'X-Api-Key': '4rhwlff8q4q860qsb9utv73x12nua8h7',
        'X-Client-Id': '75b4b010fde64b1aace9d46f3c1880d5',
        'X-Client-Secret': '7a169F9B0F4f4350Ad1a430ce4F9d215',
        'X-Device-Id': StringUtils.getDeviceUUID(),
    };

    private static instance:_HttpService;

    static getInstance(){
        if(!_HttpService.instance){
            _HttpService.instance=new _HttpService();
        }
        return _HttpService.instance;
    }

    constructor(baseURL: string = '') {
        this.baseURL = baseURL ? baseURL : this.baseURL;
        this.httpClient = axios.create();
        this.httpClient.interceptors.response.use(response => this.handleResponse(response), error => this.handleError(error));

        const accessToken = StorageService.getAccessToken();
        this.setAccessToken(accessToken);
    }

    private request(req: Request): AxiosPromise<any> {
        const axiosConfig: AxiosRequestConfig = {
            url: this.baseURL + req.url,
            method: req.method,
            responseType: req.responseType ?  req.responseType : "json",
            params: req.params,
            data: req.body,
            headers: {...this.headers, ...req.headers},
            timeout: this.timeout
        };
        return this.httpClient.request(axiosConfig);
    }

    public setAccessToken = (token) => {
        this.headers.Authorization = token ? `Bearer ${token}` : '';
    }

    public clearAccessToken = () => {
        this.headers.Authorization = '';
    }

    public get(url: string, params = {}, headers = {}): AxiosPromise<any> {
        return this.request({ method: 'GET', url: url, params: params, headers: headers});
    }

    public post(url: string, payload = {}, params = {}, headers = {}): AxiosPromise<any> {
        return this.request({ method: 'POST', url: url, params: params, body: payload, headers: headers});
    }

    public put(url: string, payload = {}, params = {}, headers = {}): AxiosPromise<any> {
        return this.request({ method: 'PUT', url: url, params: params, body: payload, headers: headers});
    }

    public patch(url: string, payload = {}, params = {}, headers = {}): AxiosPromise<any> {
        return this.request({ method: 'PATCH', url: url, params: params, body: payload, headers: headers});
    }

    public delete(url: string, params = {}, headers = {}): AxiosPromise<any> {
        return this.request({ method: 'DELETE', url: url, params: params, headers: headers});
    }

    public head(url: string, params = {}, headers = {}): AxiosPromise<any> {
        return this.request({ method: 'HEAD', url: url, params: params, headers: headers});
    }

    public options(url: string, params = {}, headers = {}): AxiosPromise<any> {
        return this.request({ method: 'OPTIONS', url: url, params: params, headers: headers});
    }

    public fetch(uri: string, params = {}, headers = {}): Promise<any> {
        let url = this.baseURL + uri;
        return fetch(url, {
            method: 'GET',
            headers: {...this.headers},
        });
    }

    public async upload(url: string, file: File, componentName: string, recordType: any = null, recordId: any = null, recordFieldName: any = null): Promise<any> {
        const headers = { ...this.headers };
        // @ts-ignore
        delete headers['Content-Type'];

        const formData = new FormData();
        formData.append('file', file);
        formData.append('componentName', componentName);
        formData.append('recordType', recordType);
        formData.append('recordId', recordId);
        formData.append('recordFieldName', recordFieldName);

        const axiosConfig: AxiosRequestConfig = {
            headers: {...headers},
        };

        return this.httpClient.post(url, formData, axiosConfig);
    }

    public async getImage(url: string): Promise<any> {
        const headers = { ...this.headers };
        return this.request({ method: 'GET', url: url, headers: headers});
    }

    public async getFile(url: string): Promise<any> {
        const axiosConfig: AxiosRequestConfig = {
            url: url,
            method: 'GET',
            responseType: "json",
            headers: {...this.headers},
            timeout: this.timeout
        };
        return this.httpClient.request(axiosConfig);
    }

    public async postFile(url: string, payload = {}): Promise<any> {
        const axiosConfig: AxiosRequestConfig = {
            url: url,
            method: 'POST',
            responseType: "json",
            data: payload,
            headers: {...this.headers},
            timeout: this.timeout
        };
        return this.httpClient.request(axiosConfig);
    }

    private handleResponse(response: AxiosResponse) {
        if (response.data?.data === undefined) {
            return {
                ...response,
                data: {
                    code: response.data.code || 200,
                    status: response.data.status || 'success',
                    message: response.data.message || '',
                    data: response.data || undefined,
                },
            }
        }

        return response;
    }

    private handleError(error: any) {
        if (error.response.status === 401) {
            const redirectUrl = window.location.href;
            window.location.href = `${SECURITY_UI_URL}/oauth2/logout?client_id=ZcwzbhUZgS3hgUKJpiPI&redirect_uri=${redirectUrl}`;
        }

        throw (error.response)
    }
}

export const HttpService = _HttpService.getInstance();
