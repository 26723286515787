import {CONSTANT_COMMON} from "../constants";

export default class StorageService {
    public getAccessToken = () => {
        return localStorage.getItem("accessToken")
    }

    public getRefreshToken = () => {
        return localStorage.getItem("refreshToken")
    }

    public setAccessToken = (token) => {
        localStorage.setItem('accessToken', token);
    }

    public setRefreshToken = (token) => {
        localStorage.setItem('refreshToken', token);
    }

    public getAccessTokenExpiredTime = () => {
        return localStorage.getItem('accessTokenExpiredTime');
    }

    public setAccessTokenExpiredTime = () => {
        const currentTime = Date.now();
        const expiredTime = currentTime + CONSTANT_COMMON.TOKEN_REFRESH_INTERVAL;
        localStorage.setItem('accessTokenExpiredTime', String(expiredTime));
    }

    public removeAccessToken = () => {
        localStorage.removeItem("accessToken")
    }

    public removeRefreshToken = () => {
        localStorage.removeItem("refreshToken")
    }

    public clearStorage = () => {
        localStorage.clear();
    }

    public storeConsoleLogData = (value, isAppend: boolean = true) => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth(); // 0-based (0 = January, 1 = February, ..., 11 = December)
        const day = currentDate.getDate();
        const hour = currentDate.getHours();
        const minute = currentDate.getMinutes();
        const second = currentDate.getSeconds();
        const formattedDate = `${year}-${month + 1}-${day} ${hour}:${minute}:${second}`;

        const key = 'ConsoleLogData';
        const newValue = "\n" + formattedDate + value;

        if (isAppend) {
            let existingValue = localStorage.getItem(key);
            if (existingValue === null) {
                localStorage.setItem(key, newValue);
            }
            else {
                existingValue += newValue;
                localStorage.setItem(key, existingValue);
            }
        }
        else {
            localStorage.setItem(key, newValue);
        }
    }
}
