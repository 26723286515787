import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "../../constants";
import {HttpService} from "@/services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.AUTH_SERVER_PREFIX}/user-filter`
const endpoints = {
  list: () => `${RESOURCE_ENDPOINT}`,
  getByWorkstationId: () => `${RESOURCE_ENDPOINT}/getByWorkstationId`,
  getOrgUserByWorkstationId: () => `${RESOURCE_ENDPOINT}/getOrgUserByWorkstationId`,
  getEmployeeInfoByUserId: () => `${RESOURCE_ENDPOINT}/getEmployeeInfoByUserId`,
  isMobileEmailExist: () => `${RESOURCE_ENDPOINT}/isMobileEmailExist`,
  showGridViewById: (id: any) => `${RESOURCE_ENDPOINT}/show-grid-view/${id}`,
}

export default class UserFilterApi {
  public list = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.list();
    return HttpService.get(url, params, headers);
  }

  public getByWorkstationId = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getByWorkstationId();
    return HttpService.get(url, params, headers);
  }

  public getOrgUserByWorkstationId = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getOrgUserByWorkstationId();
    return HttpService.get(url, params, headers);
  }

  public getEmployeeInfoByUserId = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getEmployeeInfoByUserId();
    return HttpService.get(url, params, headers);
  }

  public isMobileEmailExist = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.isMobileEmailExist();
    return HttpService.get(url, params, headers);
  }

  public showGridViewById = (id: any): AxiosPromise<any> => {
    const url = endpoints.showGridViewById(id);
    return HttpService.get(url);
  }

}
