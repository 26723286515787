import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "@/constants";
import { HttpService } from "@/services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.CORE_SERVER_PREFIX}/core-admin-dashboard`
const endpoints = {
    // Count
    countOrganization: () => `${RESOURCE_ENDPOINT}/count/organization`,
    countRegisterUser: () => `${RESOURCE_ENDPOINT}/count/register-user`,
    countServiceProvider: () => `${RESOURCE_ENDPOINT}/count/service-provider`,
    countTotalApplication: () => `${RESOURCE_ENDPOINT}/count/total-application`,
    countResolvedApplication: () => `${RESOURCE_ENDPOINT}/count/resolved-application`,
    // Summary
    summaryGrantComponent: () => `${RESOURCE_ENDPOINT}/summary/grant-component`,
    summaryAccommodationComponent: () => `${RESOURCE_ENDPOINT}/summary/accommodation-component`,
    summaryEticketComponent: () => `${RESOURCE_ENDPOINT}/summary/eticket-component`,
    summaryHealthComponent: () => `${RESOURCE_ENDPOINT}/summary/health-component`,
    summaryLibraryComponent: () => `${RESOURCE_ENDPOINT}/summary/library-component`,
    summaryLicenseComponent: () => `${RESOURCE_ENDPOINT}/summary/license-component`,
    summaryProcessLeaseComponent: () => `${RESOURCE_ENDPOINT}/summary/process-lease-component`,
    summaryResearchComponent: () => `${RESOURCE_ENDPOINT}/summary/research-component`,
    summaryTestAndConsultancyComponent: () => `${RESOURCE_ENDPOINT}/summary/test-and-consultancy-component`,
    summaryTrainingComponent: () => `${RESOURCE_ENDPOINT}/summary/training-component`,
    summaryVirtualGalleryComponent: () => `${RESOURCE_ENDPOINT}/summary/virtual-gallery-component`,
}

export default class CoreAdminDashboardApi {
    public countOrganization = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.countOrganization();
        return HttpService.post(url, payload, params, headers);
    }

    public countRegisterUser = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.countRegisterUser();
        return HttpService.post(url, payload, params, headers);
    }

    public countServiceProvider = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.countServiceProvider();
        return HttpService.post(url, payload, params, headers);
    }

    public countTotalApplication = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.countTotalApplication();
        return HttpService.post(url, payload, params, headers);
    }

    public countResolvedApplication = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.countResolvedApplication();
        return HttpService.post(url, payload, params, headers);
    }

    public summaryGrantComponent = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.summaryGrantComponent();
        return HttpService.post(url, payload, params, headers);
    }

    public summaryAccommodationComponent = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.summaryAccommodationComponent();
        return HttpService.post(url, payload, params, headers);
    }

    public summaryEticketComponent = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.summaryEticketComponent();
        return HttpService.post(url, payload, params, headers);
    }

    public summaryHealthComponent = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.summaryHealthComponent();
        return HttpService.post(url, payload, params, headers);
    }

    public summaryLibraryComponent = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.summaryLibraryComponent();
        return HttpService.post(url, payload, params, headers);
    }

    public summaryLicenseComponent = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.summaryLicenseComponent();
        return HttpService.post(url, payload, params, headers);
    }

    public summaryProcessLeaseComponent = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.summaryProcessLeaseComponent();
        return HttpService.post(url, payload, params, headers);
    }

    public summaryResearchComponent = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.summaryResearchComponent();
        return HttpService.post(url, payload, params, headers);
    }

    public summaryTestAndConsultancyComponent = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.summaryTestAndConsultancyComponent();
        return HttpService.post(url, payload, params, headers);
    }

    public summaryTrainingComponent = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.summaryTrainingComponent();
        return HttpService.post(url, payload, params, headers);
    }

    public summaryVirtualGalleryComponent = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.summaryVirtualGalleryComponent();
        return HttpService.post(url, payload, params, headers);
    }
}
