export default class JsonUtils {
    public jsonString = (value: any) => {
        return JSON.stringify(Array.from(value.entries()));
    }

    public jsonObj = (parameters: any): any => {
        if (!parameters || typeof (parameters) === 'object') {
            try {
                return JSON.parse(parameters || '{}')
            } catch (err) {
            }
        }
        return JSON.stringify(parameters)
    }

    public encodeJson = (param: {}) => {
        return encodeURIComponent(JSON.stringify(param));
    }

    public decodeJson = (param: any) => {
        return JSON.parse(decodeURIComponent(param));
    }

    public base64Encode = (unencoded: string): string => {
        return btoa(unencoded);
    };

    public base64Decode = (encoded: string, salt: string): string => {
        const decodeString =  atob(encoded);
        return decodeString.replace(salt, '');
    };
}
