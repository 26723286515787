// CDN Server
import _FileApi from "./Cdn/File.api";
import _CsvApi from "./Cdn/Csv.api";

// Auth Server
import _GroupApi from "./Auth/Group.api";
import _OauthApi from "./Auth/Oauth.api";
import _PermissionApi from "./Auth/Permission.api";
import _ResourceApi from "./Auth/Resource.api";
import _RoleApi from "./Auth/Role.api";
import _ScopeApi from "./Auth/Scope.api";
import _UserApi from "./Auth/User.api";
import _UserFilterApi from "./Auth/UserFilter.api";
import _WorkspaceApi from "./Auth/Workspace.api";
import _OrganizationApi from "./Auth/Organization.api";
import _OrganogramApi from "./Auth/Organogram.api";
import _EmployeeApi from "./Auth/Employee.api";
import _RequestAccessApi from "./Auth/RequestAccess.api";
import _ApplicantProfileApi from "./Auth/ApplicantProfile.api";
import _ApplicantProfileEducationalQualificationApi from "./Auth/ApplicantProfileEducationalQualification.api";
import _ApplicantProfilePreviousExperienceApi from "./Auth/ApplicantProfilePreviousExperience.api";
import _ApplicantProfileAwardsScholarshipApi from "./Auth/ApplicantProfileAwardsScholarship.api";
import _ApplicantProfilePublicationApi from "./Auth/ApplicantProfilePublication.api";
import _ApplicantProfileTrainingSeminarCourseApi from "./Auth/ApplicantProfileTrainingSeminarCourse.api";
import _InstituteHeadApi from "./Auth/InstituteHead.api";
import _OrganogramDesignationApi from "./Auth/OrganogramDesignation.api";
import _OAuthClientApi from "./Auth/OAuthClient.api";
import _UserNidDetailApi from "./Auth/UserNidDetail.api";

// Core Server
import _LookupApi from "./Core/Lookup.api";
import _LocationApi from "./Core/Location.api";
import _ComponentApi from "./Core/Component.api";
import _ComponentAssignmentApi from "./Core/ComponentAssignment.api";
import _PostApi from "./Core/Post.api";
import _OptionApi from "./Core/Option.api";
import _TemplateApi from "./Core/Template.api";
import _HelpManualApi from "./Core/HelpManual.api";
import _HelpFaqApi from "./Core/HelpFaq.api";
import _ExportScmApi from "./Core/ExportScm.api";
import _ImportScmApi from "./Core/ImportScm.api";
import _FackApi from "./Core/Fack.api";
import _OrganizationCategoryApi from "./Core/OrganizationCategory.api";
import _DepartmentApi from "./Core/Department.api";
import _ExamApi from "./Core/Exam.api";
import _ApiResourceApi from "./Core/ApiResource.api";
import _FinancialYearApi from "./Core/FinancialYear.api";
import _EnumApi from "./Core/Enum.api";
import _BankApi from "./Core/Bank.api";
import _BankBranchApi from "./Core/BankBranch.api";
import _OrganizationBankAccountApi from "./Core/OrganizationBankAccount.api";
import _DesignationApi from "./Auth/Designation.api";
import _DepartmentSubjectApi from "./Core/DepartmentSubject.api";
import _FacultySchoolApi from "./Core/FacultySchool.api";
import _InstituteOrganizationApi from "./Core/InstituteOrganization.api";
import _AutomationRuleApi from "./Core/AutomationRule.api";
import _WorkflowApprovalApi from "./Core/WorkflowApproval.api";
import _WorkflowStepApi from "./Core/WorkflowStep.api";
import _WorkflowTransitionApi from "./Core/WorkflowTransition.api";
import _ServicesApi from "./Core/Services.api";
import _ServiceAssignmentApi from "./Core/ServiceAssignment.api";
import _ServiceMappingApi from "./Core/ServiceMapping.api";
import _NoticeApi from "./Core/Notice.api";
import _AdvertisementApi from "./Core/Advertisement.api";
import _OwnerShipTypeApi from "./Core/OwnerShipType.api";
import _CommitteeMemberInfoApi from "./Core/CommitteeMemberInfo.api";
import _LeaseCommitteeApi from "./Core/Committee.api";
import _PaymentGatewayApi from "./Core/PaymentGateway.api";
import _PaymentApi from "./Core/Payment.api";
import _PaymentHistoryApi from "./Core/PaymentHistory.api";
import _PaymentIpnLogApi from "./Core/PaymentIpnLog.api";
import _PaymentTransactionApi from "./Core/PaymentTransaction.api";
import _PaymentRefundApi from "./Core/PaymentRefund.api";
import _PaymentRefundTransactionApi from "./Core/PaymentRefundTransaction.api";
import _PaymentEchallanApi from "./Core/PaymentEchallan.api";
import _PaymentServiceWiseRegistryApi from "./Core/PaymentServiceWiseRegistry.api";
import _UnitApi from "./Core/Unit.api";
import _CNFAgentRepresentativeApi from "./Core/CNFAgentRepresentative.api";
import _VesselApi from "./Core/Vessel.api";
import _PortApi from "./Core/Port.api";
import _ProjectApi from "./Core/Project.api";
import _ExchangeRateApi from "./Core/ExchangeRate.api";
import _TermConditionApi from "./Core/TermCondition.api";
import _DoptorServiceApi from "./Core/DoptorService.api";
import _EnothiServiceWiseRegistryApi from "./Core/EnothiServiceWiseRegistry.api";
import _WebhookApi from "./Core/Webhook.api";
import _TemplateVariableApi from "./Core/TemplateVariable.api";
import _CoreAdminDashboardApi from "./Core/CoreAdminDashboard.api";

// CMS Server
import _PageApi from "./Cms/Page.api";
import _BlocksApi from "./Cms/Blocks.api";
import _MenuApi from "./Cms/Menu.api";
import _MediaApi from "./Cms/Media.api";
import _CategoriesApi from "./Cms/Categories.api";

// License
import _ProductApi from "./License/Product.api";
import _LicenseRenewalFeeApi from "./License/LicenseRenewalFee.api";
import _ManufacturerApi from "./License/Manufacturer.api";
import _FacilityPracticeApi from "./License/FacilityPractice.api";
import _SupplierApi from "./License/Supplier.api";

// Eticket
import _ShowExhibitTypeApi from "./Eticket/ShowExhibitType.api";
import _ShowExhibitNameApi from "./Eticket/ShowExhibitName.api";
import _GiftTypeApi from "./Eticket/GiftType.api";
import _ReviewTypeApi from "./Eticket/ReviewType.api";
import _GalleryCategoryApi from "./Eticket/GalleryCategory.api";
import _GiftEligibilitySetupApi from "./Eticket/GiftEligibilitySetup.api";
import _ShowExhibitItemApi from "./Eticket/ShowExhibitItem.api";
import _ProgramTypeApi from "./Eticket/ProgramType.api";

// Grant Server
import _FellowshipTypeApi from "./Grant/FellowshipType.api";
import _FellowshipAllowanceHeadApi from "./Grant/FellowshipAllowanceHead.api";
import _FellowshipAllowanceRateApi from "./Grant/FellowshipAllowanceRate.api";
import _ItemTypeApi from "./Grant/ItemType.api";
import _StudyTypeApi from "./Grant/StudyType.api";
import _AttachmentTypeApi from "./Core/AttachmentType.api";
import _ApplicationFeeApi from "./Grant/ApplicationFee.api";
import _FellowshipCategoryApi from "./Grant/FellowshipCategory.api";
import _PriorityProjectApi from "./Grant/PriorityProject.api";
import _SpecialAllocationRateApi from "./Grant/SpecialAllocationRate.api";
import _CircularApi from "./Grant/Circular.api";
import _CommitteeApi from "./Grant/Committee.api";
import _ServiceWiseBillAndProgressMapApi from "./Grant/ServiceWiseBillAndProgressMap.api";
import _ApplicantResultMarkPolicyApi from "./Grant/ApplicantResultMarkPolicy.api";
import _ApplicantMarkWiseAllocationApi from "./Grant/ApplicantMarkWiseAllocation.api";

// Research Server
import _JournalNameApi from "./Research/JournalName.api";
import _PublisherNameApi from "./Research/PublisherName.api";
import _JournalSubjectApi from "./Research/JournalSubject.api";
import _JournalTagApi from "./Research/JournalTag.api";
import _ResearchFieldApi from "./Research/ResearchField.api";
import _ResearchLevelApi from "./Research/ResearchLevel.api";
import _AuthorNameApi from "./Research/AuthorName.api";
import _LiveMeetingAccountApi from "./Research/LiveMeetingAccount.api";

//Library Server
import _LibraryApi from "./Library/Library.api";
import _LibraryPolicyApi from "./Library/LibraryPolicy.api";
import _LibraryAuthorApi from "./Library/LibraryAuthor.api";
import _LibraryVendorApi from "./Library/LibraryVendor.api";

// Util Server
import _ActivityLogApi from "./Util/ActivityLog.api";
import _AuditLogApi from "./Util/AuditLog.api";
import _ErrorLogApi from "./Util/ErrorLog.api";
import _IntegrationLogApi from "./Util/IntegrationLog.api";
import _ApiRequestLogApi from "./Util/ApiRequestLog.api";
import _WebsocketApi from "./Util/Websocket.api";
import _NotificationApi from "./Util/Notification.api";

// Accommodation Server
import _CommonConfigurationTypeApi from './Accommodation/CommonConfigurationType.api'

// CND Server
export const FileApi = new _FileApi();
export const CsvApi = new _CsvApi();

// Auth Server
export const GroupApi = new _GroupApi();
export const OauthApi = new _OauthApi();
export const PermissionApi = new _PermissionApi();
export const ResourceApi = new _ResourceApi();
export const RoleApi = new _RoleApi();
export const ScopeApi = new _ScopeApi();
export const UserApi = new _UserApi();
export const UserFilterApi = new _UserFilterApi();
export const WorkspaceApi = new _WorkspaceApi();
export const OrganizationApi = new _OrganizationApi();
export const OrganogramApi = new _OrganogramApi();
export const EmployeeApi = new _EmployeeApi();
export const RequestAccessApi = new _RequestAccessApi();

export const ApplicantProfileApi = new _ApplicantProfileApi();
export const InstituteHeadApi = new _InstituteHeadApi();
export const OrganogramDesignationApi = new _OrganogramDesignationApi();
export const ApplicantProfileEducationalQualificationApi = new _ApplicantProfileEducationalQualificationApi();
export const ApplicantProfilePreviousExperienceApi = new _ApplicantProfilePreviousExperienceApi();
export const ApplicantProfileAwardsScholarshipApi = new _ApplicantProfileAwardsScholarshipApi();
export const ApplicantProfilePublicationApi = new _ApplicantProfilePublicationApi();
export const ApplicantProfileTrainingSeminarCourseApi = new _ApplicantProfileTrainingSeminarCourseApi();
export const OAuthClientApi = new _OAuthClientApi();
export const UserNidDetailApi = new _UserNidDetailApi();

// Core Server
export const FackApi = new _FackApi();
export const NotificationApi = new _NotificationApi();
export const LookupApi = new _LookupApi();
export const LocationApi = new _LocationApi();
export const ComponentApi = new _ComponentApi();
export const ComponentAssignmentApi = new _ComponentAssignmentApi();
export const PostApi = new _PostApi();
export const OptionApi = new _OptionApi();
export const TemplateApi = new _TemplateApi();
export const HelpManualApi = new _HelpManualApi();
export const HelpFaqApi = new _HelpFaqApi();
export const ExportScmApi = new _ExportScmApi();
export const ImportScmApi = new _ImportScmApi();
export const OrganizationCategoryApi = new _OrganizationCategoryApi();
export const DepartmentApi = new _DepartmentApi();
export const ExamApi = new _ExamApi();
export const ApiResourceApi = new _ApiResourceApi();
export const FinancialYearApi = new _FinancialYearApi();
export const EnumApi = new _EnumApi();
export const BankApi = new _BankApi();
export const BankBranchApi = new _BankBranchApi();
export const OrganizationBankAccountApi = new _OrganizationBankAccountApi();
export const DesignationApi = new _DesignationApi();
export const DepartmentSubjectApi = new _DepartmentSubjectApi();
export const FacultySchoolApi = new _FacultySchoolApi();
export const InstituteOrganizationApi = new _InstituteOrganizationApi();
export const AutomationRuleApi = new _AutomationRuleApi();
export const WorkflowApprovalApi = new _WorkflowApprovalApi();
export const WorkflowStepApi = new _WorkflowStepApi();
export const WorkflowTransitionApi = new _WorkflowTransitionApi();
export const ServicesApi = new _ServicesApi();
export const ServiceAssignmentApi = new _ServiceAssignmentApi();
export const ServiceMappingApi = new _ServiceMappingApi();
export const NoticeApi = new _NoticeApi();
export const AdvertisementApi = new _AdvertisementApi();
export const OwnerShipTypeApi = new _OwnerShipTypeApi();
export const CommitteeMemberInfoApi = new _CommitteeMemberInfoApi();
export const LeaseCommitteeApi = new _LeaseCommitteeApi();
export const PaymentGatewayApi = new _PaymentGatewayApi();
export const PaymentApi = new _PaymentApi();
export const PaymentHistoryApi = new _PaymentHistoryApi();
export const PaymentIpnLogApi = new _PaymentIpnLogApi();
export const PaymentTransactionApi = new _PaymentTransactionApi();
export const PaymentRefundApi = new _PaymentRefundApi();
export const PaymentRefundTransactionApi = new _PaymentRefundTransactionApi();
export const PaymentEchallanApi = new _PaymentEchallanApi();
export const PaymentServiceWiseRegistryApi = new _PaymentServiceWiseRegistryApi();
export const UnitApi = new _UnitApi();
export const CNFAgentRepresentativeApi = new _CNFAgentRepresentativeApi();
export const VesselApi = new _VesselApi();
export const PortApi = new _PortApi();
export const ProjectApi = new _ProjectApi();
export const ExchangeRateApi = new _ExchangeRateApi();
export const TermConditionApi = new _TermConditionApi();
export const DoptorServiceApi = new _DoptorServiceApi();
export const EnothiServiceWiseRegistryApi = new _EnothiServiceWiseRegistryApi();
export const WebhookApi = new _WebhookApi();
export const TemplateVariableApi = new _TemplateVariableApi();
export const CoreAdminDashboardApi = new _CoreAdminDashboardApi();

// CMS Server
export const PageApi = new _PageApi();
export const BlocksApi = new _BlocksApi();
export const MenuApi = new _MenuApi();
export const MediaApi = new _MediaApi();
export const CategoriesApi = new _CategoriesApi();

// License
export const ProductApi = new _ProductApi();
export const LicenseRenewalFeeApi = new _LicenseRenewalFeeApi();
export const ManufacturerApi = new _ManufacturerApi();
export const FacilityPracticeApi = new _FacilityPracticeApi();
export const SupplierApi = new _SupplierApi();

// Eticket Server
export const ShowExhibitTypeApi = new _ShowExhibitTypeApi();
export const ShowExhibitNameApi = new _ShowExhibitNameApi();
export const ReviewTypeApi = new _ReviewTypeApi();
export const GalleryCategoryApi = new _GalleryCategoryApi();
export const GiftTypeApi = new _GiftTypeApi();
export const GiftEligibilitySetupApi = new _GiftEligibilitySetupApi();
export const ShowExhibitItemApi = new _ShowExhibitItemApi();
export const ProgramTypeApi = new _ProgramTypeApi();

//Grant Server
export const FellowshipTypeApi = new _FellowshipTypeApi();
export const FellowshipAllowanceHeadApi = new _FellowshipAllowanceHeadApi();
export const FellowshipAllowanceRateApi = new _FellowshipAllowanceRateApi();
export const ItemTypeApi = new _ItemTypeApi();
export const StudyTypeApi = new _StudyTypeApi();
export const AttachmentTypeApi = new _AttachmentTypeApi();
export const FellowshipCategoryApi = new _FellowshipCategoryApi();
export const PriorityProjectApi = new _PriorityProjectApi();
export const SpecialAllocationRateApi = new _SpecialAllocationRateApi();
export const ApplicationFeeApi = new _ApplicationFeeApi();
export const CircularApi = new _CircularApi();
export const CommitteeApi = new _CommitteeApi();
export const ServiceWiseBillAndProgressMapApi = new _ServiceWiseBillAndProgressMapApi();
export const ApplicantResultMarkPolicyApi = new _ApplicantResultMarkPolicyApi();
export const ApplicantMarkWiseAllocationApi = new _ApplicantMarkWiseAllocationApi();

//Research Server
export const JournalNameApi = new _JournalNameApi();
export const PublisherNameApi = new _PublisherNameApi();
export const JournalSubjectApi = new _JournalSubjectApi();
export const JournalTagApi = new _JournalTagApi();
export const ResearchFieldApi = new _ResearchFieldApi();
export const ResearchLevelApi = new _ResearchLevelApi();
export const AuthorNameApi = new _AuthorNameApi();
export const LiveMeetingAccountApi = new _LiveMeetingAccountApi();

//Library Server
export const LibraryApi = new _LibraryApi();
export const LibraryPolicyApi = new _LibraryPolicyApi();
export const LibraryAuthorApi = new _LibraryAuthorApi();
export const LibraryVendorApi = new _LibraryVendorApi();

// Util
export const ActivityLogApi = new _ActivityLogApi();
export const AuditLogApi = new _AuditLogApi();
export const ErrorLogApi = new _ErrorLogApi();
export const IntegrationLogApi = new _IntegrationLogApi();
export const ApiRequestLogApi = new _ApiRequestLogApi();
export const WebsocketApi = new _WebsocketApi();

// Accommodation Server
export const CommonConfigurationTypeApi = new _CommonConfigurationTypeApi()
